import React from 'react'

const Ecosystem = (): React.ReactElement => (
  <section className="ecosystem">
    <h2>Ecosystem</h2>
    <ol>
      <li>
        <a
          href="https://reactrouter.com/web/guides/quick-start"
          target="_blank"
          rel="noreferrer"
        >
          react-router
        </a>
      </li>
      <li>
        <a
          href="https://github.com/pmndrs/jotai"
          target="_blank"
          rel="noreferrer"
        >
          jotai
        </a>
      </li>
      <li>
        <a
          href="https://reactcommunity.org/react-transition-group/"
          target="_blank"
          rel="noreferrer"
        >
          react-transition-group
        </a>
      </li>
      <li>
        <a
          href="https://testing-library.com/docs/react-testing-library/intro/"
          target="_blank"
          rel="noreferrer"
        >
          testing-library
        </a>
      </li>
      <li>
        <a
          href="https://chrome.google.com/webstore/detail/react-developer-tools/fmkadmapgofadopljbjfkapdkoienihi"
          target="_blank"
          rel="noreferrer"
        >
          React Developer Tools
        </a>
      </li>
    </ol>
  </section>
)

export default Ecosystem
